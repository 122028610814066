<template>
  <b-card
      title="分组配置"
  >
    <div class="custom-search">
<!--      <div class="mb-3">
        <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="roleFilter"
            :options="roleOptions"
            style="width:25%"
            placeholder="请选择机构"
            :reduce="val => val.value"
            @input="changeStatus"
        />
      </div>-->
      <!-- advance search input -->
      <b-row class="mb-2">
        <b-col cols="8" md="3" style="border-right:1px solid #d8d6de">
          <el-tree :data="groups"  :props="groupProps" node-key="id" default-expand-all :expand-on-click-node="false" style="overflow:auto;max-height: 80vh"> <!--@node-click="onTreeNodeClick"-->
            <div class="custom-tree-node" slot-scope="{ node, data }">

              <div  @click="onTreeNodeClick(data,node)" style="width: 100%" >
                <span>{{ data.name }}</span>
              </div>

              <div>
                <el-button type="text" size="mini" >
                  <b-button size="sm" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="btn-icon rounded-circle" style="padding: 0.2rem;"  v-b-modal.modal-relation @click="onAddButtonClick(data)">
                    <feather-icon icon="PlusIcon"/>
                  </b-button>
                </el-button>
                <el-button type="text" size="mini" @click="() => deleteTenantGroup(node, data)">
                  <b-button size="sm" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="btn-icon rounded-circle" style="padding: 0.2rem;">
                    <feather-icon icon="XIcon"/>
                  </b-button>
                </el-button>
              </div>

            </div>
          </el-tree>
        </b-col>
        <b-col cols="16" md="9">
          <h3 class="tCenter mb-2">分组:{{devicePathStr}}</h3>
          <!-- table -->
          <vue-good-table
              :columns="columns"
              :rows="rows"
              :rtl="direction"
              :sort-options="{
              enabled: false,
            }"
              :search-options="{
              enabled: false,
              externalQuery: searchTerm }"
              :select-options="{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
              :pagination-options="{
              enabled: true,
              perPage:queryParams.pageSize
            }"
              theme="my-theme"
          >
            <template slot="table-row" slot-scope="props">

              <span v-if="props.column.field === 'deviceStatus'" class="text-nowrap">
                <span>{{ formatterDeviceStatus(props.row.deviceStatus) }}</span>
              </span>

              <span v-else>{{ props.formattedRow[props.column.field] }}</span>
            </template>

            <!-- pagination -->
            <template
                slot="pagination-bottom"
                slot-scope="props"
            >
              <div class="tCenter">
                <b-pagination
                    :value="1"
                    :total-rows="queryParams.total"
                    :per-page="queryParams.pageSize"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @change="currentPageHandleChange"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18"/>
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18"/>
                  </template>
                </b-pagination>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </div>

    <b-modal id="modal-relation" title="创建节点" centered cancel-title="取消" ok-title="确定" @ok="addTenantGroup">
      <div>
        <div class="input-group">
          <input type="text" class="form-control" placeholder="请输入节点名称" v-model="addNodeName">
        </div>
      </div>
    </b-modal>

  </b-card>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BButton, BModal
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import vSelect from 'vue-select'
import request from '@/api/request'

let id = 1000
export default {
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      groups: [],
      groupProps: {
        label: 'name',
        children: 'childNodes'
      },
      dir: false,
      roleFilter: null,
      columns: [
        {
          label: '设备ID',
          field: 'deviceId',
        },
        {
          label: '产品名称',
          field: 'productName',
        },
        {
          label: '设备状态',
          field: 'deviceStatus',
        },
      ],
      rows: [],
      searchTerm: '',
      props: {
        label: 'name',
        children: 'zones'
      },

      //分页实体
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: null,
        tenantGroupId: null,
      },

      devicePathStr:"",
      addNodeName:"",
      addTargetNode:null,
      deviceStatusOptions: [
        {
          value: 0,
          label: '未激活'
        }
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getTableList();
    this.deviceStatusOptionsFormat();
  },
  methods: {

    getTableList() {
      request.get('tob/tenant/tenantGroup/listTenantGroupsById', {
      }).then(res => {
        if (res.data.success) {
          this.groups = res.data.data;
        }
      })
    },

    onAddButtonClick(parent){
      this.addTargetNode = parent;
      this.addNodeName = "";
    },

    addTenantGroup(params) {
      params.preventDefault();//禁止关闭

      if (this.addNodeName == ""){
        this.$message.error('请填写标签名') ;
        return ;
      }

      request.post('tob/tenant/tenantGroup/addTenantGroup', {parentGroupId:this.addTargetNode.id , name : this.addNodeName  }).then(res => {
        if (res.data.success) {
          this.$message.success('增加节点成功')

          this.$nextTick(() => {//关闭
            this.$bvModal.hide('modal-relation')
          })

          this.getTableList();
        }
      })
    },

    deleteTenantGroup(clientTree, node) {
      if (!node.parent) {
        this.$message.error('根节点不可删除')
        return
      }

      request.delete('tob/tenant/tenantGroup/deleteTenantGroup' ,{tenantGroupId: node.id }).then(res => {
        if (res.data.success) {
          this.$message.success('删除成功')
          this.getTableList();
/*          if (this.queryParams.tenantGroupId!=null&&this.queryParams.tenantGroupId!=undefined){
            this.deviceTableList();
          }*/
        }
      })
    },

    onTreeNodeClick(data, node, v) {
      this.queryParams.tenantGroupId = data.id;
      this.deviceTableList();
    },

    deviceTableList(){
      request.post('tob/tenant/tenantGroup/pageDeviceConfigByTenantGroupId', {
        pageSize: this.queryParams.pageSize,
        currentPage: this.queryParams.currentPage,
        tenantGroupId: this.queryParams.tenantGroupId,
      }).then(res => {
        if (res.data.success) {
          this.devicePathStr = res.data.data.groupPathStr;
          this.rows = res.data.data.pageDevice.data;
          this.queryParams.total = res.data.data.pageDevice.count
        }
      })
    },

    currentPageHandleChange(currentPage) {
      this.queryParams.currentPage = currentPage
      this.deviceTableList();
    },

    deviceStatusOptionsFormat() {
      request.get('tob/bDevice/getDeviceStatusEnums', {
      }).then(res => {
        if (res.data.success) {
          var enums = res.data.data;
          this.deviceStatusOptions = [];
          for (const key in enums) {
            var value = enums[key];
            var enumObject = {
              value: key - 0,
              label: value,
            }

            this.deviceStatusOptions.push(enumObject);
          }
        }
      })
    },

    formatterDeviceStatus(val) {
      for (let item of this.deviceStatusOptions) {
        if (item.value === val) {
          return item.label
        }
      }
      return val
    },
/*

    changeStatus(val) {
      console.log(val)
    },
*/

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding-right: 8px;
}
</style>
